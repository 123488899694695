/* transation-style */
.head_data {
  color: #495057;
  border: 1px solid #e9ecef;
  border-width: 1px 0 1px 0;
  padding: 0.5rem;
  font-weight: 500;
}

.box-space .form-group {
  margin: 0rem 0 !important;
}

.heading {
  font-size: 20px !important;
  line-height: 10px !important;
}

.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
  padding: 2px !important;
}

.p-breadcrumb {
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  padding: 0.5rem !important;
}

.leftspace {
  padding-left: 10px !important;
}

.scan_item {
  padding-left: 9% !important;
}

.p-card .p-card-content {
  padding: 0 !important;
}

.p-card .p-card-body {
  padding: 0 !important;
}

.bordercolor {
  border: 0.15rem solid transparent;
  border: 2px solid #dee2e6 !important;
  border-image-slice: 1;
  padding: 1rem;
}

.billing-add {
  font-weight: 600 !important;
  font-size: 1.25rem;
}

.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: #fdbe33 !important;
  color: #ffffff !important;
  padding: 1.5rem;
  border-top-right-radius: 5px !important;
  border-top-left-radius: 5px !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: #fff !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #495057 !important;
  border-color: transparent;
  background: #e9ecef;
}

.bordere {
  border: 0.15rem solid transparent;
  border: 2px solid #dee2e6 !important;
  border-image-slice: 1;
  padding: 1rem;
  margin-top: 1.5rem;
}

.p-dialog .p-dialog-content {
  background: whitesmoke !important;
  color: #495057;
  border: 2px solid #ffffff;
  padding: 1.5rem !important;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.p-dialog .p-dialog-header {
  padding: 1rem !important;
  background: #ffffff;
  border-bottom: none !important;
  color: #495057;
  padding: 1.5rem !important;
  border-radius: 0px;
}

@media (min-width: 576px) {
  .form-inline .form-control {
    display: inline-flex !important;
    width: auto;
    vertical-align: middle;
  }
}

.textle {
  text-align: left;
}

.boxspabottunspacece {
  padding: 20px;
}

.bottunspace {
  margin-bottom: 20px;
}

.boxspace {
  padding: 20px;
}

.placeholders {
  margin-bottom: 30px;
  text-align: center;
}

.placeholders h4 {
  margin-bottom: 0;
  font-size: 36px;
}

.panel-info {
  padding-bottom: 10px;
  /* border: 2px solid #3c3593; */
}

.panel {
  border-radius: 25px;
  margin-bottom: 18px;
  background-color: #fff;
}

.panel-body {
  margin-top: 10px;
}

.bar-card {
  border: none;
  border-radius: 18px;
}

.panel-info > .panel-heading {
  border-radius: inherit;
  color: #fff;
  background-color: #2d4050;
  /* background-image: linear-gradient(to right, #3c3592, #5147c9); */
  border-color: #3e3797;
  font-size: 18px;
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}

.panel-success {
  padding-bottom: 10px;
  /* border: 2px solid #3c3593; */
}

.panel-success > .panel-heading {
  border-radius: inherit;
  color: #fff;
  background-color: #2d4050;
  /* background-image: linear-gradient(to right, #3c3592, #5147c9); */
  border-color: #3e3797;
  font-size: 18px;
}

.panel-warning {
  padding-bottom: 10px;
  /* border: 2px solid #3c3593; */
}

.panel-warning > .panel-heading {
  border-radius: inherit;
  color: #fff;
  background-color: #2d4050;
  /* background-image: linear-gradient(to right, #3c3592, #5147c9); */
  border-color: #3e3797;
  font-size: 18px;
}

.panel-danger {
  padding-bottom: 10px;
  /* border: 2px solid #3c3593; */
}

.panel-danger > .panel-heading {
  border-radius: inherit;
  color: #fff;
  background-color: #2d4050;
  /* background-image: linear-gradient(to right, #3c3592, #5147c9); */
  border-color: #3e3797;
  font-size: 18px;
}

.panel-primary {
  padding-bottom: 10px;
  /* border: 2px solid #3c3593; */
}

.panel-primary > .panel-heading {
  border-radius: inherit;
  color: #fff;
  background-color: #2d4050;
  /* background-image: linear-gradient(to right, #3c3592, #5147c9); */
  border-color: #3e3797;
  font-size: 18px;
}

.boxx .cards {
  border: none;
  border-radius: 18px;
  box-shadow: none;
  /* box-shadow: 0 2px 10px rgb(0 0 0 / 10%) !important; */
}

.bux {
  /* box-shadow: 0 2px 10px rgb(0 0 0 / 10%) !important;
  background: #fff; */
  justify-content: space-around;
}

.bux .btn {
  padding: 0.2rem 0.1rem !important;
}

.margin-y {
  margin: 2rem -17px 0 !important;
}

.fa-4x {
  font-size: 3rem;
  color: #fdbe33;
}

.display-4 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2;
  text-align: left;
}

.text-uppercase {
  text-transform: uppercase !important;
  text-align: left;
}

.service-card {
  text-align: center;
  /* box-shadow: 0 2px 2px rgb(0 0 0 / 20%); */
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 0.1);
}

.service-card:hover {
  box-shadow: 4px 4px 4px rgb(58 105 243 / 30%);
  transform: translate(0, -6px);
  box-shadow: 0 0px 0px rgb(0 0 0 / 20%);
}

.m-top {
  margin: 0 0 5rem 0;
}

/* responsiveness */
@media screen and (min-width: 679px) and (max-width: 1320px) {
  .btn-success {
    margin: 0 0px !important;
    padding: -10px !important;
    font-size: 12px !important;
  }

  .btn-primary {
    margin: 0 0px !important;
    padding: -10px !important;
    font-size: 12px !important;
  }
}

.cross-button {
  display: grid;
  align-items: center;
  background: #f2f2f2;
  margin: 11px 0 0 0;
  padding: 3px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

@media (max-width: 768px) {
  .cross-button {
    margin: 11px 0 0 15px;
  }

  .panel-heading {
    padding: 10px 8px;
  }

  .panel {
    margin: 10px;
  }

  .m-top {
    margin: -2rem 0 5rem 0;
  }

  .m-button {
    margin-bottom: -2rem 0 5rem;
  }

  .service-card {
    text-align: center;
    /* box-shadow: 0 2px 2px rgb(0 0 0 / 20%); */
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 0.1);
  }

  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-menuitem-text,
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-menuitem-icon,
  .p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link
    .p-submenu-icon {
    color: #fff !important;
    font-size: 14px;
  }

  p-menubar
    .p-menubar-root-list
    > .p-menuitem
    > .p-menuitem-link:not(.p-disabled):hover {
    background: #01454a;
  }
}

.user-area:hover .user-menu {
  display: inline-block;
}

@media (max-width: 768px) {
  .user-area:hover .user-menu {
    display: block;
  }
}
