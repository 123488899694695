.fa {
  font-size: 35px;
  width: 55px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
}
.top-bar .top-bar-item {
  display: flex;
  align-items: center;
}

.about .lead {
  color: #02183b;
  font-size: 15px;
  font-weight: 400;
  text-align: justify;
  letter-spacing: 0px;
  padding: 15px;
}

.about .lead ul li {
  font-size: 15px;
}

/* unvisited link */
a:link {
  color: black;
}

/* visited link */
a:visited {
  color: black;
}

/* mouse over link */
a:hover {
  color: green;
}

/* selected link */
a:active {
  color: black;
}

/* unvisited link */
.footermy-contact a:link {
  color: white;
}

/* visited link */
.footermy-contact a:visited {
  color: white;
}

/* mouse over link */
.footermy-contact a:hover {
  color: green;
}

/* selected link */
.footermy-contact a:active {
  color: white;
}
